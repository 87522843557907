<template>
  <!--
    The view for the NotifyWizard-component.
    Used to send message to people.
  -->
  <Portlet
    :title="$t('notify')"
    :sub-title="$t('smsView.sendMessage')"
    icon="flag"
  >
    <NotifyWizard
      :installation-id="installationId"
      :recipient-id="recipientId"
    />
  </Portlet>
</template>

<script>
export default {
  name: "Notify",
  components: {
    NotifyWizard: () => import('@/components/NotifyWizard/NotifyWizard.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    recipientId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('notifyWizard')
    }
  }
}
</script>
